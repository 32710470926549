/* Own Customization */
  :root{
    --vz-primary-rgb:103, 58, 183;
    --color01:#673ab7;
    --color02: #0891ff;
    --color03: #ff8c37;
    --color04: #3b464c;
    --colorLive: #ce2c2c;
    --colorSuccess: #0ab39c;
    --colorDanger: #ce2c2c;
    --shades01: #fff;
    --shades02: #898989;
    --shades03: #676767;
    --shades04: #3f3f3f;
    --shades05: #393939;
    --shades06: #252525;
    --shades07: #131313;
    --shades08: #000;
  }
/* Customize */
.card-body-des{font-size: 14px;line-height: 1.4;}
.hamburger-icon span{background-color: var(--shades01);}

.btn-status{background: transparent;border: 1px solid transparent;}

.auth-one-bg-position{height: 550px !important;}
.auth-page-wrapper .auth-page-content{padding-top: 50px;}

.Toastify__toast-theme--light {background: var(--shades07);color: var(--shades01);}
.badge-soft-success{color: var(--shades01);background-color: #047438;}
.badge-soft-danger {color: var(--shades01);background-color: #f06548;}
[data-layout-mode="dark"] .table-light {background-color: var(--shades01);color: var(--shades02);}
textarea.form-control{min-height: calc(1.5em + 8rem + 2px);}
.logo{line-height: 100px;}
.table-nowrap th, .table-nowrap td{white-space: unset;}

@media screen and (max-width:480px) {
  .table-nowrap th, .table-nowrap td{white-space: nowrap;}
}