/* New Theme Styling - Subhajit*/

:root{
  --color01: #6A00E5;
  --color02: #0891ff;
  --color03: #FF8C37;
  --color04: #3B464C;
  --color05: #00bcd4;
  --colorLive: #CE2C2C;
  --colorSuccess: #2e7d32;
  --colorDanger: #CE2C2C;
  --shades01: #ffffff;
  --shades02: #898989;
  --shades03: #676767;
  --shades04: #3F3F3F;
  --shades05: #393939;
  --shades06: #252525;
  --shades07: #131313;
  --shades08: #000000;
  --shades09:#F5F5F5;
  --shades10:#F2F2F2;
  --shades11:#E0E0E0;
  --whatsapp-color: #30D171;
  --facebook-color: #395A94;
  --twitter-color: #11A2ED;
}
body{background-color:var(--shades06);}

[data-layout="vertical"][data-sidebar="dark"] .navbar-menu{background-color:var(--shades07); border-color: var(--shades06);}
[data-layout="vertical"][data-sidebar="dark"][data-sidebar-size="sm"] .navbar-brand-box{background-color:var(--shades07);}
.logo{line-height: 70px;}
[data-layout="vertical"][data-sidebar-size="lg"] .logo .logo-sm{display: inline-block;}
.logo span.logo-lg{display: none;}
[data-layout="vertical"][data-sidebar="dark"] .navbar-nav .nav-link{color:var(--shades01);}
[data-layout="vertical"][data-sidebar="dark"] .menu-title{color:var(--shades03);}
.menu-title span{padding: 0 20px;display: block;}
[data-layout="vertical"][data-sidebar="dark"] .menu-title{margin: 20px 0 0;}
.navbar-menu .navbar-nav .nav-sm .nav-link{padding: 6px 12px !important;}
.navbar-menu .menu-dropdown{background-color: var(--shades07);padding: 10px 0;}
[data-layout="vertical"][data-sidebar="dark"] .navbar-nav .nav-sm .nav-link{color:var(--shades02);}
.navbar-menu .navbar-nav .nav-sm .nav-link:before{ left: 0; top: 14px;width: 6px; height: 2px;background-color:var(--color05) !important;}
[data-layout="vertical"][data-sidebar="dark"] .navbar-nav .nav-sm .nav-link.active:before{opacity: 1 !important;background-color:var(--color05) !important;}
.navbar-menu .navbar-nav .nav-link i{color:var(--color05);}

#page-topbar{background-color:var(--shades07);}
.topbar-user{background-color:var(--shades06);}
.footer{background-color:var(--shades07);}
.page-title-box{background-color:var(--shades07);}
.table .table-light{background-color:var(--shades07) !important;}
[data-layout-mode="dark"] .table-light th, [data-layout-mode="dark"] .table-light td{background-color:var(--shades07);}
[data-layout-mode="dark"] .table-light th{border-bottom-width: 2px; border-color: rgba(255,255,255,0.1);}
.table > :not(caption) > * > *{box-shadow: none; border-color: rgba(255,255,255,0.05);}

.page-link{background-color: var(--shades07);border-color:var(--shades06);}
.page-link:hover{background-color: var(--shades06);}
.page-item.active .page-link{background-color: var(--color05); border-color: var(--color05);}


.form-control, .form-select,.select2-selection.form-select__control{background-color: var(--shades06) !important; border-color: rgba(255,255,255,0.1) !important;}
.select2-selection.form-select__menu{background-color: var(--shades06) !important; border-color: rgba(255,255,255,0.1) !important;}
.select2-selection.form-select__option.select2-selection.form-select__option--is-focused,.select2-selection.form-select__option,.select2-selection.form-select__option:active{background-color: var(--shades06) !important;}
.select2-selection.form-select__value-container.select2-selection .form-select__multi-value{background-color: var(--shades07);}
.select2-selection.form-select__value-container.select2-selection .form-select__multi-value__remove:hover{background-color: var(--shades07);}

.text-primary{color: var(--color05) !important;}
.form-label{color: var(--shades03);}
.page-item.active .page-link{background-color: var(--shades05);border-color: var(--shades05);}

.btn-primary , .btn-primary:hover{background-color: var(--color05);color: var(--shades01);border-color: var(--color05);}
.btn-success , .btn-success:hover{background-color: var(--colorSuccess);border-color: var(--colorSuccess);}
.btn-unfeatured , .btn-unfeatured:hover{color: var(--shades01);background-color: var(--shades04);border-color: var(--shades04);}
.btn-active , .btn-active:hover{background: var(--colorSuccess);border: 1px solid var(--colorSuccess);}
.btn-featured , .btn-featured:hover{background: var(--color03);border: 1px solid var(--color03);}
.btn-danger , .btn-danger:hover{background: var(--colorDanger);border: 1px solid var(--colorDanger);}
.btn-delete , .btn-delete:hover{background: var(--colorLive);border: 1px solid var(--colorLive);}
.btn-cancel , .btn-reset , .btn-reset:hover{background: var(--shades04);color: var(--shades01);border: 1px solid var(--shades04);}
[data-layout-mode="dark"] .btn-light,[data-layout-mode="dark"] .btn-light:hover{background-color: var(--shades06);border-color: var(--shades05);}

.form-check-input:checked {background-color: var(--color05);border: 1px solid var(--color05);}
.form-check-input{width: 16px;height: 16px;background-color: var(--shades03);}

.flatpickr-calendar{background-color: var(--shades06);}
.flatpickr-calendar.arrowTop:before{border-bottom-color:var(--shades06);}
.flatpickr-calendar.arrowTop:after{border-bottom-color:var(--shades06);}
.flatpickr-calendar .flatpickr-months .flatpickr-month{background-color: var(--shades07);}
.flatpickr-weekdays{background-color: var(--shades07); border: 0;}
.flatpickr-calendar span.flatpickr-weekday{background-color: var(--shades07);}
.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months{background-color: var(--shades07);}
.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months *{color: var(--shades01);}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus,.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{background: var(--color05); border-color: var(--color05);}
.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus{background-color: var(--shades06);}
.flatpickr-day.today{border-color: var(--color05);}

.ck.ck-toolbar,.ck.ck-editor__main > .ck-editor__editable{background-color: var(--shades06) !important;border-color: var(--shades05) !important;}

.badge-soft-success{background-color: var(--colorSuccess);}
.badge-soft-danger{background-color: var(--colorDanger);}

.image-container{width: 100%; max-width: 320px; margin: 0 auto;}
.image-container img{width: 100%; height: 1000%; object-fit: cover;}
.image-container img[src=""]{display: none;}


.tab-navigation-menu{padding-left: 0;list-style: none;display: flex;flex-wrap: wrap; gap: 40px;border-bottom: 1px solid var(--shades04);}
.tab-navigation-menu .item{position: relative;}
.tab-navigation-menu .item .item-link{position: relative; display: flex;align-items: center;height: 40px;font-size: 13px;font-weight: 400;text-transform: uppercase;letter-spacing: 0.5px;}
.tab-navigation-menu .item .item-link i{font-size: 18px; color: var(--color05);}
.tab-navigation-menu .item .item-link:after{content:''; position: absolute;width: 100%;border-bottom: 3px solid var(--color05);left: 0;bottom: -1px;transform: scale(0); transform-origin: center; transition: all 0.3s ease;}
.tab-navigation-menu .item .item-link.active:after,.tab-navigation-menu .item .item-link:hover:after{transform: scale(1);}

.card{background-color:var(--shades07);border-radius: 6px;}
.card .card-header{background-color:var(--shades07);border-color: var(--shades06);}
.card .card-body{border-color:var(--shades05)}
.card .card-footer{background-color:var(--shades07);border-color:var(--shades06); display: flex; justify-content: flex-start; gap: 10px;}
.card .card-title{color: var(--color05);}
.card .card-subtitle{margin: 0 0 10px;font-size: 14px;text-transform: capitalize;font-weight: 600;}
.card .card-descrption{font-size: 14px;font-weight: 300;}
.card .subtext{font-size: 13px;font-weight: 300;color: var(--shades02);}
.card .meta{font-size: 13px;font-weight: 300;color: var(--shades02);}
.card .card-block+.card-block{border-top:1px solid var(--shades05); margin: 20px 0 0; padding: 20px 0 0;}

.interest-list{display: flex; flex-wrap: wrap; gap: 10px;}
.interest-list .interest-item{position: relative;}
.interest-list .interest-title{height: 40px;background-color: var(--shades06);padding: 0 16px;display: flex;align-items: center;justify-content: center;border-radius: 30px;text-transform: capitalize;font-size: 13px;font-weight: 300;border: 1px solid var(--shades05);}

.popover{background-color:var(--shades07);border-color: var(--shades06);}

.loader{position: relative;}
.loader svg{transform: scale(1);}
.loader svg path{fill: transparent; stroke-width: 0.2; stroke: var(--shades04); margin: 0 2px;}
.loader svg path:nth-child(1){stroke-dasharray: 185;stroke-dashoffset: 185;animation: animate-path1 5s linear forwards infinite;}
.loader svg path:nth-child(2){stroke-dasharray: 87;stroke-dashoffset: 87;animation: animate-path2 5s linear forwards infinite;}
.loader svg path:nth-child(3){stroke-dasharray: 74;stroke-dashoffset: 74;animation: animate-path3 5s linear forwards infinite;}

@keyframes animate-path1{
  0%{stroke-dashoffset: 0;}
  30%{stroke-dashoffset: 185;}
  60%{stroke-dashoffset: 370;fill: transparent;stroke: var(--shades04);}
  80%{stroke-dashoffset: 370;fill: var(--shades01);stroke: var(--shades01);}
  100%{stroke-dashoffset: 370;fill: var(--shades01); stroke: var(--shades01);}
}
@keyframes animate-path2{
  0%{stroke-dashoffset: 0;}
  30%{stroke-dashoffset: 87;}
  60%{stroke-dashoffset: 174;fill: transparent;stroke: var(--shades04);}
  80%{stroke-dashoffset: 174;fill: var(--shades01);stroke: var(--shades01);}
  100%{stroke-dashoffset: 174;fill: var(--shades01); stroke: var(--shades01);}
}
@keyframes animate-path3{
  0%{stroke-dashoffset: 0;}
  30%{stroke-dashoffset: 74;}
  60%{stroke-dashoffset: 148;fill: transparent;stroke: var(--shades04);}
  80%{stroke-dashoffset: 148;fill: var(--shades01);stroke: var(--shades01);}
  100%{stroke-dashoffset: 148;fill: var(--shades01); stroke: var(--shades01);}
}