
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #46545e;
    color: white;
    font-size: 10px;
    display: inline-block;
    padding: 4px;
    margin: 0 2px;
    margin-bottom: 5px;
    border-radius: 2px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
   .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }

